"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandidateViewContextProvider = exports.useCandidateView = exports.CandidateViewContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const utils_1 = require("@bemlo/utils");
const can = (permissions, action, scope) => {
    const parts = scope.split('/');
    // Find the value of the most specific supplied permission:
    while (parts.length > 0) {
        const permissionScope = parts.join('/');
        const permission = permissions[permissionScope];
        if (permission?.[action] !== undefined) {
            return !!permission[action];
        }
        parts.pop();
    }
    return false;
};
const getValidHomeMunicipality = (candidate) => {
    if (!candidate.homeMunicipality) {
        return undefined;
    }
    const municipalities = (0, utils_1.getMunicipalities)({
        countries: candidate.homeCountry ? [candidate.homeCountry] : undefined,
    });
    return municipalities.includes(candidate.homeMunicipality)
        ? candidate.homeMunicipality
        : undefined;
};
const getValidSpecializations = (candidate, market) => {
    const { profession, specializations } = candidate;
    const allSpecializations = (0, utils_1.getSpecializations)({
        market,
        professions: profession ? [profession] : undefined,
    });
    return specializations.filter((specialization) => allSpecializations.includes(specialization));
};
const parseCandidate = (candidate, market) => {
    return {
        ...candidate,
        homeMunicipality: getValidHomeMunicipality(candidate),
        specializations: getValidSpecializations(candidate, market),
    };
};
exports.CandidateViewContext = (0, react_1.createContext)(null);
const useCandidateView = () => {
    const context = (0, react_1.useContext)(exports.CandidateViewContext);
    if (!context) {
        throw new Error('useCandidateView must be used within a CandidateViewContext');
    }
    return context;
};
exports.useCandidateView = useCandidateView;
const CandidateViewContextProvider = (props) => {
    const [editState, setEditState] = (0, react_1.useState)(null);
    const { compact = false, isMe, market, permissions, candidate } = props;
    const data = parseCandidate(editState?.data ?? candidate, market);
    const editData = editState?.data ?? null;
    const editScopes = editState?.scope ?? null;
    const actor = {
        can: can.bind(null, props.permissions),
    };
    const isEditing = (scope) => {
        if (!editState) {
            return false;
        }
        if (!scope) {
            return true;
        }
        // Check whether we're editing the specified scope.
        // Elements scoped `/foo/bar` will be visible if the edit scope is either `/foo/bar` or `/foo`.
        return `${scope}/`.startsWith(`${editState.scope}/`);
    };
    const patchEditData = (data) => {
        setEditState((currentEditState) => {
            return currentEditState
                ? { ...currentEditState, data: { ...currentEditState.data, ...data } }
                : null;
        });
    };
    const toggleEditMode = (scope) => {
        setEditState((currentEditState) => {
            return currentEditState
                ? null
                : {
                    data: candidate,
                    scope,
                };
        });
    };
    const trimAndUpdate = (name) => {
        return () => {
            const value = editData?.[name];
            if (typeof value === 'string') {
                patchEditData?.({ [name]: value.trim() });
            }
        };
    };
    const update = (name) => {
        return (value) => {
            patchEditData?.({ [name]: value });
        };
    };
    return ((0, jsx_runtime_1.jsx)(exports.CandidateViewContext.Provider, { value: {
            actor,
            compact,
            data,
            editData,
            editScopes,
            isEditing,
            isMe,
            market,
            patchEditData,
            permissions,
            candidate,
            setEditState,
            toggleEditMode,
            trimAndUpdate,
            update,
        }, children: props.children }));
};
exports.CandidateViewContextProvider = CandidateViewContextProvider;
