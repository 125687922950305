"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Countries = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useCandidateView_1 = require("../hooks/useCandidateView");
const useCandidateViewAlternatives_1 = require("../hooks/useCandidateViewAlternatives");
const scopes_1 = require("../scopes");
const Countries = () => {
    const { actor, data, isEditing, patchEditData } = (0, useCandidateView_1.useCandidateView)();
    const { countryAlternatives } = (0, useCandidateViewAlternatives_1.useCandidateViewAlternatives)();
    const t = (0, translate_1.useTranslations)();
    const scope = scopes_1.scopes.interests.countries();
    if (!actor.can('read', scope)) {
        return null;
    }
    const label = t.candidate_view.interests.country_other();
    const icon = 'GlobeAltIcon';
    const { countries } = data;
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { disabled: !actor.can('update', scope), label: label, labelIcon: icon, onChange: (countries) => patchEditData({ countries }), options: countryAlternatives, value: countries }));
    }
    const content = countries.map((country) => t.$country(country)).join(', ');
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.Countries = Countries;
