"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCodes = exports.TenderWaiverStatus = exports.UnitType = exports.UnitRelation = exports.ShiftBookingStatus = exports.ShiftApplicationStatus = exports.DynamicShiftStatus = exports.WouldRecommend = exports.WorkPlaceRelationshipToApplicant = exports.WorkHours = exports.WorkForm = exports.WhichRegionAlternative = exports.UnitedKingdomWhichRegionAlternative = exports.SwedenWhichRegionAlternative = exports.SpecialWhichRegionAlternative = exports.NorwayWhichRegionAlternative = exports.WhichDepartmentToWorkInAlternative = exports.TenderStatus = exports.TenderPricing = exports.Specialization = exports.NurseSpecialization = exports.DoctorSpecialization = exports.TenderScheduleType = exports.ShiftHours = exports.RequirementFulfillmentStatus = exports.ResourceAvailabilitySource = exports.ReferentType = exports.Profession = exports.UnitedKingdomMunicipality = exports.SwedenMunicipality = exports.NorwayMunicipality = exports.Municipality = exports.TimeReportStatus = exports.Market = exports.Locale = exports.LicenseStatus = exports.TimeReportSource = exports.JournalSystem = exports.JobType = exports.HowMuchWorkAlternative = exports.HowMuchExperienceAlternative = exports.FileUploadType = exports.FileUploadAuthor = exports.DriversLicense = exports.Day = exports.CurrentEmploymentStatus = exports.Currency = exports.Country = exports.CandidateType = exports.CandidateRequirementType = void 0;
exports.ScheduleDraftStatus = exports.SchedulingPeriodResourceDemandType = exports.SchedulingPeriodStatus = exports.ShiftActivity = exports.ShiftType = exports.OnCallType = exports.BroadcastMessageRecipientGroup = exports.ShiftAvailability = exports.SchedulingViolationType = exports.COUNTRY_REGION_MUNICIPALITY_HIERARCHY = exports.StatisticsSeriesKey = exports.StatisticsTimeUnit = exports.EmploymentType = void 0;
var candidate_requirement_type_1 = require("./candidate-requirement-type");
Object.defineProperty(exports, "CandidateRequirementType", { enumerable: true, get: function () { return candidate_requirement_type_1.CandidateRequirementType; } });
var candidate_type_1 = require("./candidate-type");
Object.defineProperty(exports, "CandidateType", { enumerable: true, get: function () { return candidate_type_1.CandidateType; } });
var country_1 = require("./country");
Object.defineProperty(exports, "Country", { enumerable: true, get: function () { return country_1.Country; } });
var currency_1 = require("./currency");
Object.defineProperty(exports, "Currency", { enumerable: true, get: function () { return currency_1.Currency; } });
var current_employment_status_1 = require("./current-employment-status");
Object.defineProperty(exports, "CurrentEmploymentStatus", { enumerable: true, get: function () { return current_employment_status_1.CurrentEmploymentStatus; } });
var day_1 = require("./day");
Object.defineProperty(exports, "Day", { enumerable: true, get: function () { return day_1.Day; } });
var drivers_license_1 = require("./drivers-license");
Object.defineProperty(exports, "DriversLicense", { enumerable: true, get: function () { return drivers_license_1.DriversLicense; } });
var file_upload_author_1 = require("./file-upload-author");
Object.defineProperty(exports, "FileUploadAuthor", { enumerable: true, get: function () { return file_upload_author_1.FileUploadAuthor; } });
var file_upload_type_1 = require("./file-upload-type");
Object.defineProperty(exports, "FileUploadType", { enumerable: true, get: function () { return file_upload_type_1.FileUploadType; } });
var how_much_experience_alternative_1 = require("./how-much-experience-alternative");
Object.defineProperty(exports, "HowMuchExperienceAlternative", { enumerable: true, get: function () { return how_much_experience_alternative_1.HowMuchExperienceAlternative; } });
var how_much_work_alternative_1 = require("./how-much-work-alternative");
Object.defineProperty(exports, "HowMuchWorkAlternative", { enumerable: true, get: function () { return how_much_work_alternative_1.HowMuchWorkAlternative; } });
var job_type_1 = require("./job-type");
Object.defineProperty(exports, "JobType", { enumerable: true, get: function () { return job_type_1.JobType; } });
var journal_system_1 = require("./journal-system");
Object.defineProperty(exports, "JournalSystem", { enumerable: true, get: function () { return journal_system_1.JournalSystem; } });
var time_report_source_1 = require("./time-report-source");
Object.defineProperty(exports, "TimeReportSource", { enumerable: true, get: function () { return time_report_source_1.TimeReportSource; } });
var license_status_1 = require("./license-status");
Object.defineProperty(exports, "LicenseStatus", { enumerable: true, get: function () { return license_status_1.LicenseStatus; } });
var locale_1 = require("./locale");
Object.defineProperty(exports, "Locale", { enumerable: true, get: function () { return locale_1.Locale; } });
var market_1 = require("./market");
Object.defineProperty(exports, "Market", { enumerable: true, get: function () { return market_1.Market; } });
var time_report_status_1 = require("./time-report-status");
Object.defineProperty(exports, "TimeReportStatus", { enumerable: true, get: function () { return time_report_status_1.TimeReportStatus; } });
var municipality_1 = require("./municipality");
Object.defineProperty(exports, "Municipality", { enumerable: true, get: function () { return municipality_1.Municipality; } });
Object.defineProperty(exports, "NorwayMunicipality", { enumerable: true, get: function () { return municipality_1.NorwayMunicipality; } });
Object.defineProperty(exports, "SwedenMunicipality", { enumerable: true, get: function () { return municipality_1.SwedenMunicipality; } });
Object.defineProperty(exports, "UnitedKingdomMunicipality", { enumerable: true, get: function () { return municipality_1.UnitedKingdomMunicipality; } });
var profession_1 = require("./profession");
Object.defineProperty(exports, "Profession", { enumerable: true, get: function () { return profession_1.Profession; } });
var referent_type_1 = require("./referent-type");
Object.defineProperty(exports, "ReferentType", { enumerable: true, get: function () { return referent_type_1.ReferentType; } });
var resource_availability_source_1 = require("./resource-availability-source");
Object.defineProperty(exports, "ResourceAvailabilitySource", { enumerable: true, get: function () { return resource_availability_source_1.ResourceAvailabilitySource; } });
var requirement_fulfillment_status_1 = require("./requirement-fulfillment-status");
Object.defineProperty(exports, "RequirementFulfillmentStatus", { enumerable: true, get: function () { return requirement_fulfillment_status_1.RequirementFulfillmentStatus; } });
var shift_hours_1 = require("./shift-hours");
Object.defineProperty(exports, "ShiftHours", { enumerable: true, get: function () { return shift_hours_1.ShiftHours; } });
var tender_schedule_type_1 = require("./tender-schedule-type");
Object.defineProperty(exports, "TenderScheduleType", { enumerable: true, get: function () { return tender_schedule_type_1.TenderScheduleType; } });
var specialization_1 = require("./specialization");
Object.defineProperty(exports, "DoctorSpecialization", { enumerable: true, get: function () { return specialization_1.DoctorSpecialization; } });
Object.defineProperty(exports, "NurseSpecialization", { enumerable: true, get: function () { return specialization_1.NurseSpecialization; } });
Object.defineProperty(exports, "Specialization", { enumerable: true, get: function () { return specialization_1.Specialization; } });
var tender_pricing_1 = require("./tender-pricing");
Object.defineProperty(exports, "TenderPricing", { enumerable: true, get: function () { return tender_pricing_1.TenderPricing; } });
var tender_status_1 = require("./tender-status");
Object.defineProperty(exports, "TenderStatus", { enumerable: true, get: function () { return tender_status_1.TenderStatus; } });
var which_department_to_work_in_alternative_1 = require("./which-department-to-work-in-alternative");
Object.defineProperty(exports, "WhichDepartmentToWorkInAlternative", { enumerable: true, get: function () { return which_department_to_work_in_alternative_1.WhichDepartmentToWorkInAlternative; } });
var which_region_alternative_1 = require("./which-region-alternative");
Object.defineProperty(exports, "NorwayWhichRegionAlternative", { enumerable: true, get: function () { return which_region_alternative_1.NorwayWhichRegionAlternative; } });
Object.defineProperty(exports, "SpecialWhichRegionAlternative", { enumerable: true, get: function () { return which_region_alternative_1.SpecialWhichRegionAlternative; } });
Object.defineProperty(exports, "SwedenWhichRegionAlternative", { enumerable: true, get: function () { return which_region_alternative_1.SwedenWhichRegionAlternative; } });
Object.defineProperty(exports, "UnitedKingdomWhichRegionAlternative", { enumerable: true, get: function () { return which_region_alternative_1.UnitedKingdomWhichRegionAlternative; } });
Object.defineProperty(exports, "WhichRegionAlternative", { enumerable: true, get: function () { return which_region_alternative_1.WhichRegionAlternative; } });
var work_form_1 = require("./work-form");
Object.defineProperty(exports, "WorkForm", { enumerable: true, get: function () { return work_form_1.WorkForm; } });
var work_hours_1 = require("./work-hours");
Object.defineProperty(exports, "WorkHours", { enumerable: true, get: function () { return work_hours_1.WorkHours; } });
var workplace_relationship_to_applicant_1 = require("./workplace-relationship-to-applicant");
Object.defineProperty(exports, "WorkPlaceRelationshipToApplicant", { enumerable: true, get: function () { return workplace_relationship_to_applicant_1.WorkPlaceRelationshipToApplicant; } });
var would_recommend_1 = require("./would-recommend");
Object.defineProperty(exports, "WouldRecommend", { enumerable: true, get: function () { return would_recommend_1.WouldRecommend; } });
var dynamic_shift_status_1 = require("./dynamic-shift-status");
Object.defineProperty(exports, "DynamicShiftStatus", { enumerable: true, get: function () { return dynamic_shift_status_1.DynamicShiftStatus; } });
var shift_application_status_1 = require("./shift-application-status");
Object.defineProperty(exports, "ShiftApplicationStatus", { enumerable: true, get: function () { return shift_application_status_1.ShiftApplicationStatus; } });
var shift_booking_status_1 = require("./shift-booking-status");
Object.defineProperty(exports, "ShiftBookingStatus", { enumerable: true, get: function () { return shift_booking_status_1.ShiftBookingStatus; } });
var unit_relation_1 = require("./unit-relation");
Object.defineProperty(exports, "UnitRelation", { enumerable: true, get: function () { return unit_relation_1.UnitRelation; } });
var unit_type_1 = require("./unit-type");
Object.defineProperty(exports, "UnitType", { enumerable: true, get: function () { return unit_type_1.UnitType; } });
var tender_waiver_status_1 = require("./tender-waiver-status");
Object.defineProperty(exports, "TenderWaiverStatus", { enumerable: true, get: function () { return tender_waiver_status_1.TenderWaiverStatus; } });
var error_codes_1 = require("./error-codes");
Object.defineProperty(exports, "ErrorCodes", { enumerable: true, get: function () { return error_codes_1.ErrorCodes; } });
var employment_type_1 = require("./employment-type");
Object.defineProperty(exports, "EmploymentType", { enumerable: true, get: function () { return employment_type_1.EmploymentType; } });
var statistics_time_unit_1 = require("./statistics-time-unit");
Object.defineProperty(exports, "StatisticsTimeUnit", { enumerable: true, get: function () { return statistics_time_unit_1.StatisticsTimeUnit; } });
var statistics_keys_1 = require("./statistics-keys");
Object.defineProperty(exports, "StatisticsSeriesKey", { enumerable: true, get: function () { return statistics_keys_1.StatisticsSeriesKey; } });
var country_region_municipality_1 = require("./data/country-region-municipality");
Object.defineProperty(exports, "COUNTRY_REGION_MUNICIPALITY_HIERARCHY", { enumerable: true, get: function () { return country_region_municipality_1.COUNTRY_REGION_MUNICIPALITY_HIERARCHY; } });
var scheduling_violation_type_1 = require("./scheduling-violation-type");
Object.defineProperty(exports, "SchedulingViolationType", { enumerable: true, get: function () { return scheduling_violation_type_1.SchedulingViolationType; } });
var shift_availability_1 = require("./shift-availability");
Object.defineProperty(exports, "ShiftAvailability", { enumerable: true, get: function () { return shift_availability_1.ShiftAvailability; } });
var broadcast_message_recipient_group_1 = require("./broadcast-message-recipient-group");
Object.defineProperty(exports, "BroadcastMessageRecipientGroup", { enumerable: true, get: function () { return broadcast_message_recipient_group_1.BroadcastMessageRecipientGroup; } });
var on_call_type_1 = require("./on-call-type");
Object.defineProperty(exports, "OnCallType", { enumerable: true, get: function () { return on_call_type_1.OnCallType; } });
var shift_type_1 = require("./shift-type");
Object.defineProperty(exports, "ShiftType", { enumerable: true, get: function () { return shift_type_1.ShiftType; } });
var shift_activity_1 = require("./shift-activity");
Object.defineProperty(exports, "ShiftActivity", { enumerable: true, get: function () { return shift_activity_1.ShiftActivity; } });
var scheduling_period_status_1 = require("./scheduling-period-status");
Object.defineProperty(exports, "SchedulingPeriodStatus", { enumerable: true, get: function () { return scheduling_period_status_1.SchedulingPeriodStatus; } });
var scheduling_period_resource_demand_type_1 = require("./scheduling-period-resource-demand-type");
Object.defineProperty(exports, "SchedulingPeriodResourceDemandType", { enumerable: true, get: function () { return scheduling_period_resource_demand_type_1.SchedulingPeriodResourceDemandType; } });
var schedule_draft_status_1 = require("./schedule-draft-status");
Object.defineProperty(exports, "ScheduleDraftStatus", { enumerable: true, get: function () { return schedule_draft_status_1.ScheduleDraftStatus; } });
