"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const schedule_1 = require("@bemlo/schedule");
const constants_1 = require("../../../../constants");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const ResourceTitle = (props) => {
    const t = (0, translate_1.useTranslations)();
    const time = (0, schedule_1.shiftsToWorkHours)(props.shifts ?? []);
    const nameTitle = props.url ? ((0, jsx_runtime_1.jsx)(elements_1.Link, { to: props.url, styles: {
            color: constants_1.Color.BLUE,
            fontWeight: constants_1.theme.font.weight.bold,
            fontSize: constants_1.theme.font.size[6.5],
        }, children: props.title })) : ((0, jsx_runtime_1.jsx)(elements_1.Text, { fontSize: constants_1.theme.font.size[6.5], color: props.bold ? constants_1.Color.BLUE : constants_1.Color.DARKER_GREY, fontWeight: props.bold ? constants_1.theme.font.weight.bold : constants_1.theme.font.weight.book, children: props.title }));
    return ((0, jsx_runtime_1.jsx)(elements_1.Div, { styles: { height: '100%' }, children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2, items: "center", styles: {
                px: 2,
                py: 1,
                height: '100%',
                justifyContent: props.title === null ? 'center' : undefined,
            }, children: [props.avatar && ((0, jsx_runtime_1.jsx)(elements_1.Avatar, { type: props.avatar.url ? 'image' : 'initials', name: props.title, image: props.avatar.url, size: "sm" })), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, children: [props.title !== null && nameTitle, props.shifts && ((0, jsx_runtime_1.jsxs)(elements_1.Text, { fontSize: constants_1.theme.font.size['6.5'], color: constants_1.Color.DARKEST_MEDIUM_GREY, fontWeight: constants_1.theme.font.weight.book, children: [t.$date.formatDuration({ hours: time }), " / ", props.shifts.length, ' ', props.shifts.length === 1
                                    ? t.shifts.shift_one().toLocaleLowerCase()
                                    : t.shifts.shift_other().toLocaleLowerCase()] }))] })] }) }));
};
exports.ResourceTitle = ResourceTitle;
