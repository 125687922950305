"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employmentTypePlural = exports.employmentType = void 0;
const enums_1 = require("@bemlo/enums");
exports.employmentType = {
    [enums_1.EmploymentType.PERMANENT]: 'Fast ansatt',
    [enums_1.EmploymentType.HOURLY]: 'Timeansatt',
    [enums_1.EmploymentType.TEMPORARY]: 'Midlertidig ansatt',
    [enums_1.EmploymentType.CONTRACT]: 'Konsulent',
};
exports.employmentTypePlural = {
    [enums_1.EmploymentType.PERMANENT]: 'Fast ansatte',
    [enums_1.EmploymentType.HOURLY]: 'Timeansatte',
    [enums_1.EmploymentType.TEMPORARY]: 'Midlertidig ansatte',
    [enums_1.EmploymentType.CONTRACT]: 'Konsulenter',
};
