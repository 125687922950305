"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.n_hours = void 0;
const enums_1 = require("@bemlo/enums");
const n_hours = (locale) => {
    const formatNumber = (number, multipleOf = 1) => {
        return (Math.round(number / multipleOf) * multipleOf).toLocaleString(locale);
    };
    return (hours) => {
        const formattedHours = formatNumber(hours, 0.25);
        if (locale === enums_1.Locale.SV) {
            return `${formattedHours} timm${hours === 1 ? 'e' : 'ar'}`;
        }
        if (locale === enums_1.Locale.NB) {
            return `${formattedHours} timer`;
        }
        return `${formattedHours} hours`;
    };
};
exports.n_hours = n_hours;
