"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsInterestedInMatchingVacancies = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const IsInterestedInMatchingVacancies = ({ onIsInterestedInMatchingVacanciesChange, }) => {
    const { actor, candidate } = (0, useCandidateView_1.useCandidateView)();
    const { contactPreferences } = candidate;
    const t = (0, translate_1.useTranslations)();
    const [isInterestedInMatchingVacancies, setIsInterestedInMatchingVacancies] = (0, react_1.useState)(!!contactPreferences.isInterestedInMatchingVacancies);
    (0, react_1.useEffect)(() => {
        setIsInterestedInMatchingVacancies(!!contactPreferences.isInterestedInMatchingVacancies);
    }, [contactPreferences.isInterestedInMatchingVacancies]);
    const scope = scopes_1.scopes.interests.contactPreferences.isInterestedInMatchingVacancies();
    if (!actor.can('read', scope)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(elements_1.Toggle, { checked: isInterestedInMatchingVacancies, direction: "row", disabled: !actor.can('update', scope), label: t.candidate_view.interests.subscribe_to_matching_jobs(), labelSize: "small", onCheckedChange: (value) => {
            setIsInterestedInMatchingVacancies(value);
            onIsInterestedInMatchingVacanciesChange(value);
        }, styles: { w: 'full' }, subtitle: t.candidate_view.interests.we_will_notify_you_once_a_day() }));
};
exports.IsInterestedInMatchingVacancies = IsInterestedInMatchingVacancies;
