"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shifts = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const SHIFT_HOURS = (0, utils_1.arrayOfAll)()([
    enums_1.ShiftHours.DAY,
    enums_1.ShiftHours.EVENING,
    enums_1.ShiftHours.NIGHT,
]);
const Shifts = () => {
    const t = (0, translate_1.useTranslations)();
    const { actor, data, isEditing, patchEditData } = (0, useCandidateView_1.useCandidateView)();
    const { contract } = data;
    const scope = scopes_1.scopes.contract();
    if (!actor.can('read', scope) || !contract) {
        return null;
    }
    const label = t.candidate_view.contract.shift_hours();
    const icon = 'EveningIcon';
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Flex, { gap: 3, wrap: true, children: (0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { label: label, labelIcon: icon, onChange: (allowedShiftHours) => patchEditData({ contract: { ...contract, allowedShiftHours } }), options: SHIFT_HOURS.map((value) => ({
                    label: t.$shiftHours(value),
                    value,
                    key: value,
                })), value: contract.allowedShiftHours }) }));
    }
    const content = contract.allowedShiftHours.map(t.$shiftHours).join(', ');
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.Shifts = Shifts;
