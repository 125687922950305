"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullSpan = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Cell_1 = require("../components/Cell");
const Row_1 = require("../components/Row");
const useSchedule_1 = require("../useSchedule");
const FullSpan = (props) => {
    const { columnCount } = (0, useSchedule_1.useSchedule)();
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(Row_1.Row, { children: (0, jsx_runtime_1.jsx)(Cell_1.Cell, { style: { padding: 0, height: 0, position: 'sticky', left: 0 }, colSpan: columnCount, isTitle: true, children: props.children }) }) }));
};
exports.FullSpan = FullSpan;
