"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employmentTypePlural = exports.employmentType = void 0;
const enums_1 = require("@bemlo/enums");
exports.employmentType = {
    [enums_1.EmploymentType.PERMANENT]: 'Permanent',
    [enums_1.EmploymentType.HOURLY]: 'Hourly',
    [enums_1.EmploymentType.TEMPORARY]: 'Temporary',
    [enums_1.EmploymentType.CONTRACT]: 'Contractor',
};
exports.employmentTypePlural = {
    [enums_1.EmploymentType.PERMANENT]: 'Permanent',
    [enums_1.EmploymentType.HOURLY]: 'Hourly',
    [enums_1.EmploymentType.TEMPORARY]: 'Temporary',
    [enums_1.EmploymentType.CONTRACT]: 'Contractors',
};
