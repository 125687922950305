"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interests = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Countries_1 = require("./Countries");
const Departments_1 = require("./Departments");
const IsInterestedInContact_1 = require("./IsInterestedInContact");
const IsInterestedInMatchingVacancies_1 = require("./IsInterestedInMatchingVacancies");
const JobPreferences_1 = require("./JobPreferences");
const Location_1 = require("./Location");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const EditIcon_1 = require("../components/EditIcon");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const Interests = ({ onIsInterestedInContactChange, onIsInterestedInMatchingVacanciesChange, }) => {
    const { actor, isEditing, isMe } = (0, useCandidateView_1.useCandidateView)();
    const t = (0, translate_1.useTranslations)();
    const scope = scopes_1.scopes.interests();
    if (!actor.can('read', scope)) {
        return null;
    }
    if (isEditing() && !actor.can('update', scope)) {
        return null;
    }
    const isInterestedInContactScope = scopes_1.scopes.interests.contactPreferences.isInterestedInContact();
    const isInterestedInMatchingVacanciesScope = scopes_1.scopes.interests.contactPreferences.isInterestedInMatchingVacancies();
    return ((0, jsx_runtime_1.jsxs)(elements_1.Paper, { styles: { p: 3.5, position: 'relative' }, children: [(0, jsx_runtime_1.jsx)(elements_1.Flex, { items: "center", styles: { minHeight: 176, position: 'relative', width: '100%' }, children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3, grow: 1, children: [actor.can('update', scope) ? ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 1, children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { items: "start", justify: "between", children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2.5, items: "center", children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.DARK_GREEN, size: 3, children: isMe
                                                        ? t.candidate_view.interests.interests()
                                                        : t.candidate_view.interests.candidate_interests() }), (0, jsx_runtime_1.jsx)(elements_1.Icon, { backgroundColor: constants_1.Color.LIGHTER_GREEN, color: constants_1.Color.DARK_GREEN, name: "LightBulbIcon", round: true, size: "lg" })] }), (0, jsx_runtime_1.jsx)(EditIcon_1.EditIcon, { permissionScope: "interests" })] }), isMe && ((0, jsx_runtime_1.jsx)(elements_1.Text, { color: constants_1.Color.DARK_GREY, size: 6, children: t.candidate_view.interests.what_jobs_are_you_interested_in() }))] })) : ((0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.BLUE, size: 3, children: t.candidate_view.interests.candidate_interests() })), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3, children: [(0, jsx_runtime_1.jsx)(Countries_1.Countries, {}), (0, jsx_runtime_1.jsx)(Location_1.Location, {}), (0, jsx_runtime_1.jsx)(Departments_1.Departments, {}), (0, jsx_runtime_1.jsx)(JobPreferences_1.JobPreferences, {})] })] }) }), (actor.can('read', isInterestedInContactScope) ||
                actor.can('read', isInterestedInMatchingVacanciesScope)) && ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3, styles: { mt: 3 }, children: [(0, jsx_runtime_1.jsx)(elements_1.HorizontalRule, {}), (0, jsx_runtime_1.jsx)(IsInterestedInMatchingVacancies_1.IsInterestedInMatchingVacancies, { onIsInterestedInMatchingVacanciesChange: onIsInterestedInMatchingVacanciesChange }), (0, jsx_runtime_1.jsx)(IsInterestedInContact_1.IsInterestedInContact, { onIsInterestedInContactChange: onIsInterestedInContactChange })] }))] }));
};
exports.Interests = Interests;
