"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contract = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const EditIcon_1 = require("../components/EditIcon");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const Employment_1 = require("./Employment");
const Scope_1 = require("./Scope");
const Shifts_1 = require("./Shifts");
const WorkingTimeDirective_1 = require("./WorkingTimeDirective");
const Contract = () => {
    const { actor, isEditing, data } = (0, useCandidateView_1.useCandidateView)();
    const t = (0, translate_1.useTranslations)();
    const scope = scopes_1.scopes.contract();
    if (!data.contract) {
        return null;
    }
    if (!actor.can('read', scope)) {
        return null;
    }
    if (isEditing() && !actor.can('update', scope)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(elements_1.Paper, { styles: { p: 3.5, position: 'relative' }, children: (0, jsx_runtime_1.jsx)(elements_1.Flex, { items: "center", styles: { minHeight: 176, position: 'relative', width: '100%' }, children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3, grow: 1, children: [actor.can('update', scope) ? ((0, jsx_runtime_1.jsx)(elements_1.Flex, { col: true, gap: 1, children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { items: "start", justify: "between", children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2.5, items: "center", children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.BLUE, size: 3, children: t.candidate_view.contract.contract() }), (0, jsx_runtime_1.jsx)(elements_1.Icon, { backgroundColor: constants_1.Color.LIGHTER_BLUE, color: constants_1.Color.BLUE, name: "DocumentIcon", round: true, size: "lg" })] }), (0, jsx_runtime_1.jsx)(EditIcon_1.EditIcon, { permissionScope: "contract" })] }) })) : ((0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.BLUE, size: 3, children: t.candidate_view.contract.contract() })), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3, children: [(0, jsx_runtime_1.jsx)(Employment_1.Employment, {}), (0, jsx_runtime_1.jsx)(Scope_1.Scope, {}), (0, jsx_runtime_1.jsx)(Shifts_1.Shifts, {}), (0, jsx_runtime_1.jsx)(WorkingTimeDirective_1.WorkingTimeDirective, {})] })] }) }) }));
};
exports.Contract = Contract;
