"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobPreferences = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const DatePicker_1 = require("../../../elements/Form/DatePicker");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useCandidateView_1 = require("../hooks/useCandidateView");
const useCandidateViewAlternatives_1 = require("../hooks/useCandidateViewAlternatives");
const scopes_1 = require("../scopes");
const JobPreferences = () => {
    const { actor, data, isEditing, patchEditData } = (0, useCandidateView_1.useCandidateView)();
    const { howMuchWorkAlternatives, jobTypeAlternatives, workFormAlternatives } = (0, useCandidateViewAlternatives_1.useCandidateViewAlternatives)();
    const t = (0, translate_1.useTranslations)();
    const jobTypesScope = scopes_1.scopes.interests.jobTypes();
    const workFormsScope = scopes_1.scopes.interests.workForms();
    const extentScope = scopes_1.scopes.interests.extent();
    const startDateScope = scopes_1.scopes.interests.startDate();
    const currentScopes = [
        jobTypesScope,
        workFormsScope,
        extentScope,
        startDateScope,
    ];
    if (currentScopes.every((scope) => !actor.can('read', scope))) {
        return null;
    }
    const label = t.candidate_view.interests.job_type_other();
    const icon = 'BriefcaseIcon';
    const { extent, jobTypes, startDate, workForms } = data;
    if (currentScopes.some((scope) => isEditing(scope))) {
        return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3, children: [(0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, wrap: true, children: [(0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { disabled: !actor.can('update', jobTypesScope), label: label, labelIcon: icon, onChange: (jobTypes) => patchEditData({ jobTypes }), options: jobTypeAlternatives, value: jobTypes }), (0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { disabled: !actor.can('update', workFormsScope), label: t.candidate_view.interests.work_form_other(), labelIcon: "CurrencyDollarIcon", onChange: (workForms) => patchEditData({ workForms }), options: workFormAlternatives, value: workForms })] }), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, wrap: true, children: [(0, jsx_runtime_1.jsx)(elements_1.Listbox, { disabled: !actor.can('update', extentScope), label: t.candidate_view.interests.extent(), labelIcon: "ClockIcon", onChange: (extent) => patchEditData({ extent }), options: howMuchWorkAlternatives, value: extent }), (0, jsx_runtime_1.jsx)(DatePicker_1.DatePicker, { disabled: !actor.can('update', startDateScope), label: t.candidate_view.interests.earliest_start_date(), labelIcon: "CalendarIcon", onChange: (startDate) => patchEditData({
                                startDate: startDate ? t.$date(startDate).unix() : null,
                            }), value: startDate ? t.$date.unix(startDate).toDate() : undefined })] })] }));
    }
    const jobTypesContent = jobTypes
        .map((jobType) => t.$jobType(jobType))
        .join(', ');
    const workFormsContent = workForms
        .map((workForm) => t.$workForm(workForm))
        .join(', ');
    const extentContent = extent && t.$howMuchWorkAlternative(extent);
    const startDateContent = startDate && ((0, jsx_runtime_1.jsxs)("div", { children: [t.candidate_view.interests.earliest_start_date(), ' ', (0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, children: t.$date.unix(startDate).format('ll') })] }, "startDate"));
    const content = [
        jobTypesContent,
        workFormsContent,
        extentContent,
        startDateContent,
    ];
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.JobPreferences = JobPreferences;
