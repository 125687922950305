"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatPane = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ChatInput_1 = require("./ChatInput");
const ChatMessages_1 = require("./ChatMessages");
const constants_1 = require("../../constants");
const elements_1 = require("../../elements");
const translate_1 = require("../../translate");
const ChatPane = (props) => {
    const ref = (0, react_1.useRef)(null);
    const t = (0, translate_1.useTranslations)();
    const scrollToBottom = () => {
        if (!ref.current)
            return;
        ref.current.scrollTop = 99999;
    };
    (0, react_1.useEffect)(scrollToBottom, [props.conversationId, props.messages.length]);
    const handleSubmit = (message) => {
        props.onMessageSubmit(message);
    };
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, justify: "between", onFocus: props.onFocus ? () => props.onFocus?.(props.conversationId) : undefined, styles: {
            backgroundColor: constants_1.Color.WHITE,
            height: '100%',
            width: '100%',
            ...props.styles,
        }, tabIndex: 0, children: [(0, jsx_runtime_1.jsx)(ChatMessages_1.ChatMessages, { messages: props.messages, ref: ref, userId: props.userId, onToggleFlag: props.onToggleFlag }), props.isUserMember ? ((0, jsx_runtime_1.jsx)(ChatInput_1.ChatInput, { onChange: props.onMessageChange, onSubmit: handleSubmit, value: props.message })) : ((0, jsx_runtime_1.jsx)(elements_1.Callout, { icon: null, intent: "info", styles: { justifyContent: 'center', textAlign: 'center' }, children: (0, jsx_runtime_1.jsx)(elements_1.Button, { loading: props.isJoining, onClick: props.onJoinClick, children: t.chat_view.join() }) }))] }));
};
exports.ChatPane = ChatPane;
