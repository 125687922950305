"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Biography = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const BiographyHeader_1 = require("./BiographyHeader");
const BiographySummary_1 = require("./BiographySummary");
const Description_1 = require("./components/Description");
const DriversLicenses_1 = require("./components/DriversLicenses");
const Employment_1 = require("./components/Employment");
const Experience_1 = require("./components/Experience");
const FullName_1 = require("./components/FullName");
const JournalSystems_1 = require("./components/JournalSystems");
const LicenseStatuses_1 = require("./components/LicenseStatuses");
const Location_1 = require("./components/Location");
const elements_1 = require("../../../elements");
const Flex_1 = require("../../../elements/Flex");
const Paper_1 = require("../../../elements/Paper");
const FileUploads_1 = require("../file-uploads/FileUploads");
const useCandidateView_1 = require("../hooks/useCandidateView");
const ProfileProgress_1 = require("../profile-progress/ProfileProgress");
const scopes_1 = require("../scopes");
const MINIMUM_PERFECT_PROFILE_COMPLETION_PERCENTAGE = 90;
const useCalculateProfileCompletionPercentage = (candidate) => {
    const { actor } = (0, useCandidateView_1.useCandidateView)();
    const allScoredValues = [
        [1, candidate.countries],
        [1, candidate.departments],
        [1, candidate.description],
        [0.5, candidate.driversLicense],
        [2, candidate.email],
        [1, candidate.experience],
        [2, candidate.fileUploads?.[0]],
        [1, candidate.fileUploads?.[1]],
        [0.5, candidate.homeCountry],
        [1, candidate.homeMunicipality],
        ...(actor.can('read', scopes_1.scopes.information.hpr())
            ? [[3, candidate.hpr]]
            : []),
        [1, candidate.jobTypes],
        [0.5, candidate.journalSystems],
        [1, candidate.municipalities],
        ...(actor.can('read', scopes_1.scopes.information.personalIdentityNumber())
            ? [[3, candidate.personalIdentityNumber]]
            : []),
        [2, candidate.phoneNumber],
        [3, candidate.profession],
        ...(actor.can('read', scopes_1.scopes.references())
            ? [[2, candidate.references[0]]]
            : []),
        [1, candidate.regions],
        [2, candidate.specializations],
        [1, candidate.startDate],
        [1, candidate.workForms],
    ];
    const candidateScore = allScoredValues
        .filter(([, value]) => isValueSpecified(value))
        .reduce((total, [score]) => total + score, 0);
    const perfectScore = allScoredValues.reduce((total, [score]) => total + score, 0);
    // Round to the nearest 10:
    return Math.round((candidateScore / perfectScore) * 10) * 10;
};
const isValueSpecified = (value) => {
    return Array.isArray(value) ? value.length >= 1 : !!value;
};
const Biography = ({ onAddAuthorizationClick, onAddFileUploadClick, onAddReferenceClick, onEditFileUploadClick, onOpenFileUploadClick, onSendMessageClick, }) => {
    const { data, isEditing } = (0, useCandidateView_1.useCandidateView)();
    const profileCompletionPercentage = useCalculateProfileCompletionPercentage(data);
    return ((0, jsx_runtime_1.jsx)(Paper_1.Paper, { children: (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(Flex_1.Flex, { col: true, gap: 3.5, styles: { p: 3.5 }, children: isEditing('biography') ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(FullName_1.FullName, {}), (0, jsx_runtime_1.jsx)(Description_1.Description, {}), (0, jsx_runtime_1.jsx)(Location_1.Location, {}), (0, jsx_runtime_1.jsx)(elements_1.HorizontalRule, {}), (0, jsx_runtime_1.jsx)(Experience_1.Experience, {}), (0, jsx_runtime_1.jsx)(LicenseStatuses_1.LicenseStatuses, {}), (0, jsx_runtime_1.jsx)(elements_1.HorizontalRule, {}), (0, jsx_runtime_1.jsx)(JournalSystems_1.JournalSystems, {}), (0, jsx_runtime_1.jsx)(DriversLicenses_1.DriversLicenses, {}), (0, jsx_runtime_1.jsx)(Employment_1.Employment, {})] })) : ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, gap: 4, children: [(0, jsx_runtime_1.jsx)(BiographyHeader_1.BiographyHeader, { onSendMessageClick: onSendMessageClick }), (0, jsx_runtime_1.jsx)(Description_1.Description, {}), (0, jsx_runtime_1.jsx)(BiographySummary_1.BiographySummary, {}), (0, jsx_runtime_1.jsx)(FileUploads_1.FileUploads, { onAddFileUploadClick: onAddFileUploadClick, onEditFileUploadClick: onEditFileUploadClick, onOpenFileUploadClick: onOpenFileUploadClick })] })) }), profileCompletionPercentage <
                    MINIMUM_PERFECT_PROFILE_COMPLETION_PERCENTAGE && ((0, jsx_runtime_1.jsx)(ProfileProgress_1.ProfileProgress, { onAddAuthorizationClick: onAddAuthorizationClick, onAddFileUploadClick: onAddFileUploadClick, onAddReferenceClick: onAddReferenceClick, profileCompletionPercentage: profileCompletionPercentage }))] }) }));
};
exports.Biography = Biography;
