"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Departments = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useCandidateView_1 = require("../hooks/useCandidateView");
const useCandidateViewAlternatives_1 = require("../hooks/useCandidateViewAlternatives");
const scopes_1 = require("../scopes");
const Departments = () => {
    const { actor, data, isEditing, patchEditData } = (0, useCandidateView_1.useCandidateView)();
    const { departmentAlternatives } = (0, useCandidateViewAlternatives_1.useCandidateViewAlternatives)();
    const t = (0, translate_1.useTranslations)();
    const scope = scopes_1.scopes.interests.departments();
    if (!actor.can('read', scope)) {
        return null;
    }
    const icon = 'OfficeBuildingIcon';
    const label = t.candidate_view.interests.department_other();
    const { departments } = data;
    if (isEditing(scope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.MultiSelect, { disabled: !actor.can('update', scope), label: label, labelIcon: icon, onChange: (departments) => patchEditData({ departments }), options: departmentAlternatives, value: departments }));
    }
    const content = departments
        .map((department) => t.$whichDepartmentToWorkInAlternative(department))
        .join(', ');
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.Departments = Departments;
