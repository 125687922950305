import { Profession } from '../generated/graphql'

import { MenuItemArea } from './enums'
import { MarketFeature } from './enums/market-feature'
import { FeatureConfig } from './types'

export const features = (): Partial<Record<MarketFeature, FeatureConfig>> => ({
  [MarketFeature.ABOUT_US]: {
    menuItems: [
      {
        key: 'about_us',
        url: '/about',
        weight: 600,
      },
      // TODO: developer footer item requires refactoring link component to
      // support external links
    ],
  },
  [MarketFeature.BLOG]: {
    menuItems: [
      {
        key: 'blog',
        url: '/blog',
        weight: 500,
      },
    ],
  },
  [MarketFeature.COMPANY]: {
    menuItems: [
      {
        key: 'companies',
        url: '/companies',
        weight: 200,
      },
    ],
  },
  [MarketFeature.FAQ]: {
    menuItems: [
      {
        key: 'staffing_tips',
        url: '/faq',
        weight: 400,
      },
    ],
  },
  [MarketFeature.JOBS]: {
    menuItems: [
      {
        key: 'jobs',
        url: '/jobs',
        weight: 100,
      },
    ],
  },
  [MarketFeature.SALARY]: {
    menuItems: [
      {
        key: 'salary_statistics',
        url: '/lonestatistik',
        weight: 250,
      },
    ],
  },
  [MarketFeature.VARDFORBUNDET]: {
    menuItems: [
      {
        key: 'vardforbundet',
        url: '/vardforbundet',
        professions: [Profession.NURSE],
        areas: [MenuItemArea.NAVBAR],
        weight: 1000,
      },
    ],
  },
})
