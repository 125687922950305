"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftActivity = void 0;
var ShiftActivity;
(function (ShiftActivity) {
    ShiftActivity["WORK"] = "WORK";
    ShiftActivity["FLEX"] = "FLEX";
    ShiftActivity["TEAM_MEETING"] = "TEAM_MEETING";
    ShiftActivity["TRAINING"] = "TRAINING";
})(ShiftActivity || (exports.ShiftActivity = ShiftActivity = {}));
