"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const useCandidateView_1 = require("../hooks/useCandidateView");
const EditIcon = ({ onClick, permissionScope, small, }) => {
    const { actor, isEditing, toggleEditMode } = (0, useCandidateView_1.useCandidateView)();
    if (isEditing() || !actor.can('update', permissionScope)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(elements_1.Clickable, { styles: { width: 'fit-content' }, onClick: onClick ?? (() => toggleEditMode(permissionScope)), children: (0, jsx_runtime_1.jsx)(elements_1.Icon, { backgroundColor: constants_1.Color.LIGHTEST_BLUE, color: constants_1.Color.BLUE, name: "PencilIcon", round: !small, size: small ? 'sm' : 'md-plus' }) }));
};
exports.EditIcon = EditIcon;
