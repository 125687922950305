"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputDetailDate = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const date_1 = require("@bemlo/date");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const Flex_1 = require("../../../elements/Flex");
const useCandidateView_1 = require("../hooks/useCandidateView");
const InputDetailDate = ({ field, icon, label, permissionScope, placeholders, value, }) => {
    const { actor, isEditing, update } = (0, useCandidateView_1.useCandidateView)();
    if (!actor.can('read', permissionScope)) {
        return null;
    }
    if (isEditing(permissionScope)) {
        return ((0, jsx_runtime_1.jsx)(elements_1.Form.DatePicker, { disabled: !actor.can('update', permissionScope), label: label, labelIcon: icon, onChange: (value) => update(field)(value && (0, date_1.toUnixTime)(value)), placeholder: placeholders.read, value: value, showYearPicker: true }));
    }
    // Only render the row if we can show a value or an "Add a value..." placeholder:
    if (!value &&
        (!actor.can('update', permissionScope) || !placeholders.update)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2, items: "center", styles: { overflowWrap: 'anywhere' }, children: [(0, jsx_runtime_1.jsx)(elements_1.Icon, { color: constants_1.Color.BLUE, name: icon, size: "sm" }), (0, jsx_runtime_1.jsx)(Flex_1.Flex, { gap: 1, children: (0, jsx_runtime_1.jsx)(elements_1.Text, { color: value ? constants_1.Color.BLACK : constants_1.Color.DARKEST_MEDIUM_GREY, size: 6, children: value?.getFullYear() || `${placeholders.update}...` }) })] }, icon));
};
exports.InputDetailDate = InputDetailDate;
