"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsInterestedInContact = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const IsInterestedInContact = ({ onIsInterestedInContactChange, }) => {
    const { actor, candidate } = (0, useCandidateView_1.useCandidateView)();
    const { contactPreferences } = candidate;
    const t = (0, translate_1.useTranslations)();
    const [isInterestedInContact, setIsInterestedInContact] = (0, react_1.useState)(!!contactPreferences.isInterestedInContact);
    (0, react_1.useEffect)(() => {
        setIsInterestedInContact(!!contactPreferences.isInterestedInContact);
    }, [contactPreferences.isInterestedInContact]);
    const scope = scopes_1.scopes.interests.contactPreferences.isInterestedInContact();
    if (!actor.can('read', scope)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(elements_1.Toggle, { checked: isInterestedInContact, direction: "row", disabled: !actor.can('update', scope), label: t.candidate_view.interests.make_my_contact_information_visible_to_employers(), labelSize: "small", onCheckedChange: (value) => {
            setIsInterestedInContact(value);
            onIsInterestedInContactChange(value);
        }, styles: { w: 'full' }, subtitle: t.candidate_view.interests.you_may_be_contacted_regarding_job_opportunities() }));
};
exports.IsInterestedInContact = IsInterestedInContact;
