"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const constants_1 = require("../../../constants");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const useCandidateView_1 = require("../hooks/useCandidateView");
const EditBar = ({ fixedPosition, onSave }) => {
    const t = (0, translate_1.useTranslations)();
    const { editData, isMe, candidate, setEditState } = (0, useCandidateView_1.useCandidateView)();
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsx)(elements_1.EditBar, { marginBottom: 8, fixedPosition: fixedPosition, isEditing: !!editData, children: (0, jsx_runtime_1.jsx)(elements_1.Container, { children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, items: "center", justify: "between", children: [(0, jsx_runtime_1.jsx)(elements_1.Text, { bold: true, color: constants_1.Color.DARKEST_BLUE, children: t.candidate_view.edit.you_are_editing_candidate(isMe ? undefined : candidate.fullName) }), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 3, items: "center", children: [(0, jsx_runtime_1.jsx)(elements_1.Button, { design: "primary", onClick: () => setEditState(null), size: "sm", text: true, children: t.candidate_view.base.cancel() }), (0, jsx_runtime_1.jsx)(elements_1.Button, { design: "success", icon: "CheckIcon", loading: isSaving, onClick: async () => {
                                    if (!editData) {
                                        return;
                                    }
                                    try {
                                        setIsSaving(true);
                                        await onSave(candidate, editData);
                                    }
                                    finally {
                                        setIsSaving(false);
                                    }
                                    setEditState(null);
                                }, size: "sm", children: t.candidate_view.base.save() })] })] }) }) }));
};
exports.EditBar = EditBar;
