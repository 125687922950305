export enum MarketFeature {
  ABOUT_US = 'ABOUT_US',
  BLOG = 'BLOG',
  COLLECTIVE_AGREEMENTS = 'COLLECTIVE_AGREEMENTS',
  COMPANY = 'COMPANY',
  CONSULTANT_COUNTS = 'CONSULTANT_COUNTS',
  CV = 'CV',
  DRIFT = 'DRIFT',
  DRIP_RATE = 'DRIP_RATE',
  FAQ = 'FAQ',
  JOBS = 'JOBS',
  JOB_WORKPLACE = 'JOB_WORKPLACE',
  JOBS_SUBSCRIPTION = 'JOBS_SUBSCRIPTION',
  LANDING_PAGE = 'LANDING_PAGE',
  NORWAY_TOGGLE = 'NORWAY_TOGGLE',
  PROFESSION_SELECTOR = 'PROFESSION_SELECTOR',
  PROFILE = 'PROFILE',
  REGION_SPECIFIC = 'REGION_SPECIFIC',
  SALARY = 'SALARY',
  VARDFORBUNDET = 'VARDFORBUNDET',
}
