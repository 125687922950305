"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkingTimeDirective = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const utils_1 = require("@bemlo/utils");
const translate_1 = require("../../../translate");
const Detail_1 = require("../components/Detail");
const useCandidateView_1 = require("../hooks/useCandidateView");
const scopes_1 = require("../scopes");
const WorkingTimeDirective = () => {
    const t = (0, translate_1.useTranslations)();
    const { actor, data } = (0, useCandidateView_1.useCandidateView)();
    const { contract } = data;
    const scope = scopes_1.scopes.contract();
    if (!actor.can('read', scope) || !contract) {
        return null;
    }
    const label = t.candidate_view.contract.working_time_directive();
    const dayBreakLabel = t.candidate_view.contract.default_day_break();
    const weekBreakLabel = t.candidate_view.contract.default_week_break();
    const icon = 'ExclamationIcon';
    const dayBreak = contract.defaultDayBreak;
    const weekBreak = contract.defaultWeekBreak
        ? (() => {
            const [day, time] = contract.defaultWeekBreak.split(' ');
            const formattedWeekBreak = `${t.$date().isoWeekday(parseInt(day)).format('ddd')} ${time}`;
            return formattedWeekBreak;
        })()
        : null;
    if (!dayBreak && !weekBreak) {
        return null;
    }
    const content = [
        dayBreak ? `${dayBreakLabel} ${dayBreak}` : null,
        weekBreak ? `${weekBreakLabel} ${weekBreak}` : null,
    ]
        .filter(utils_1.isTruthy)
        .join(', ');
    return (0, jsx_runtime_1.jsx)(Detail_1.Detail, { content: content, heading: label, icon: icon });
};
exports.WorkingTimeDirective = WorkingTimeDirective;
