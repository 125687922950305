"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandidateView = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const EditBar_1 = require("./components/EditBar");
const Content_1 = require("./Content");
const useCandidateView_1 = require("./hooks/useCandidateView");
const elements_1 = require("../../elements");
__exportStar(require("./types"), exports);
const CandidateView = (props) => {
    return ((0, jsx_runtime_1.jsxs)(useCandidateView_1.CandidateViewContextProvider, { compact: props.compact, isMe: props.isMe, market: props.market, permissions: props.permissions, candidate: props.candidate, children: [(0, jsx_runtime_1.jsx)(EditBar_1.EditBar, { fixedPosition: props.fixedEditBarPosition, onSave: props.actions.onSave }), (0, jsx_runtime_1.jsx)(elements_1.Section, { spacing: "compact", children: (0, jsx_runtime_1.jsx)(elements_1.Container, { children: (0, jsx_runtime_1.jsx)(Content_1.Content, { ...props }) }) })] }));
};
exports.CandidateView = CandidateView;
