"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploads = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const FileUpload_1 = require("./FileUpload");
const elements_1 = require("../../../elements");
const translate_1 = require("../../../translate");
const SectionHeading_1 = require("../components/SectionHeading");
const useCandidateView_1 = require("../hooks/useCandidateView");
const { CV, HOSP, IVO, REFERENCE, OTHER, SPECIALIZATION } = enums_1.FileUploadType;
/**
 * Creates the cards in which file uploads are displayed or can be added.
 */
const useFileUploadCards = (candidate) => {
    const { actor, market } = (0, useCandidateView_1.useCandidateView)();
    const { fileUploads: uploads = [] } = candidate;
    const slots = [];
    if (actor.can('update', 'fileUploads')) {
        const referenceFileUploads = uploads.filter((f) => f.uploadType === REFERENCE);
        const allowedFileUploadTypes = (0, utils_1.fileUploadTypesForMarket)(market);
        slots.push(...[
            {
                key: 'cv-1',
                type: CV,
                fileUpload: uploads.find((f) => f.uploadType === CV),
            },
            {
                key: 'ivo-1',
                type: IVO,
                fileUpload: uploads.find((f) => f.uploadType === IVO),
            },
            {
                key: 'hosp-1',
                type: HOSP,
                fileUpload: uploads.find((f) => f.uploadType === HOSP),
            },
            {
                key: 'reference-1',
                type: REFERENCE,
                fileUpload: referenceFileUploads[0],
            },
            {
                key: 'reference-2',
                type: REFERENCE,
                fileUpload: referenceFileUploads[1],
            },
            {
                key: 'specialization',
                type: SPECIALIZATION,
                fileUpload: uploads.find((f) => f.uploadType === SPECIALIZATION),
            },
        ].filter((upload) => allowedFileUploadTypes.includes(upload.type)));
    }
    for (const fileUpload of uploads) {
        if (!slots.some((slot) => slot.fileUpload?.id === fileUpload.id)) {
            slots.push({
                type: fileUpload.uploadType,
                fileUpload,
                key: fileUpload.id,
            });
        }
    }
    slots.sort((a, b) => (b.fileUpload ? 1 : 0) - (a.fileUpload ? 1 : 0));
    return slots;
};
const FileUploads = ({ onAddFileUploadClick, onEditFileUploadClick, onOpenFileUploadClick, }) => {
    const { actor, data } = (0, useCandidateView_1.useCandidateView)();
    const fileUploadCards = useFileUploadCards(data);
    const t = (0, translate_1.useTranslations)();
    if (!fileUploadCards.length || !actor.can('read', 'fileUploads')) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, children: [(0, jsx_runtime_1.jsx)(SectionHeading_1.SectionHeading, { children: t.candidate_view.file_uploads.documents() }), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3, items: "start", children: [(0, jsx_runtime_1.jsx)(elements_1.Div, { styles: {
                            display: 'grid',
                            gap: 3.5,
                            gridTemplateColumns: 'repeat(auto-fill, minmax(155px, 1fr))',
                            w: 'full',
                        }, children: fileUploadCards.map(({ fileUpload, type, key }) => ((0, jsx_runtime_1.jsx)(FileUpload_1.FileUpload, { fileUpload: fileUpload ?? null, onAddClick: onAddFileUploadClick, onEditClick: onEditFileUploadClick, onOpenClick: onOpenFileUploadClick, uploadType: type }, fileUpload?.id ?? key))) }), actor.can('update', 'fileUploads') && ((0, jsx_runtime_1.jsx)(elements_1.Button, { design: "primary-inverted", onClick: () => onAddFileUploadClick(OTHER), size: "sm", children: t.candidate_view.file_uploads.upload_document() }))] })] }));
};
exports.FileUploads = FileUploads;
