"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employmentTypePlural = exports.employmentType = void 0;
const enums_1 = require("@bemlo/enums");
exports.employmentType = {
    [enums_1.EmploymentType.PERMANENT]: 'Tillsvidareanställd',
    [enums_1.EmploymentType.HOURLY]: 'Timanställd',
    [enums_1.EmploymentType.TEMPORARY]: 'Visstidsanställd',
    [enums_1.EmploymentType.CONTRACT]: 'Konsult',
};
exports.employmentTypePlural = {
    [enums_1.EmploymentType.PERMANENT]: 'Tillsvidareanställda',
    [enums_1.EmploymentType.HOURLY]: 'Timanställda',
    [enums_1.EmploymentType.TEMPORARY]: 'Visstidsanställda',
    [enums_1.EmploymentType.CONTRACT]: 'Konsulter',
};
