"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diffShiftNDays = void 0;
const date_1 = require("@bemlo/date");
const diffComponents = (oldStartDateTime, oldEndDateTime, days) => {
    const startDateTime = oldStartDateTime.add(days, 'days');
    const endDateTime = oldEndDateTime.add(days, 'days');
    const date = startDateTime.format(date_1.ISO_DATE_FORMAT);
    const startTime = startDateTime.format('HH:mm');
    const endTime = endDateTime.format('HH:mm');
    return {
        startDateTime,
        endDateTime,
        date,
        startTime,
        endTime,
    };
};
const diffShiftNDays = ({ startDateTime: oldStartDateTime, endDateTime: oldEndDateTime, date: _date, startTime: _startTime, endTime: _endTime, onCallTime, ...shift }, days) => {
    const shiftComponents = diffComponents(oldStartDateTime, oldEndDateTime, days);
    const onCallComponents = onCallTime
        ? diffComponents(onCallTime.startDateTime, onCallTime.endDateTime, days)
        : null;
    return {
        ...shift,
        ...shiftComponents,
        onCallTime: onCallTime && onCallComponents
            ? {
                ...onCallComponents,
                type: onCallTime.type,
            }
            : null,
    };
};
exports.diffShiftNDays = diffShiftNDays;
