"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentType = void 0;
var EmploymentType;
(function (EmploymentType) {
    EmploymentType["PERMANENT"] = "PERMANENT";
    EmploymentType["HOURLY"] = "HOURLY";
    EmploymentType["TEMPORARY"] = "TEMPORARY";
    EmploymentType["CONTRACT"] = "CONTRACT";
})(EmploymentType || (exports.EmploymentType = EmploymentType = {}));
