"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scopes = void 0;
exports.scopes = {
    biography: Object.assign(() => 'biography', {
        description: () => 'biography/description',
        employmentStatus: () => 'biography/employmentStatus',
        experience: () => 'biography/experience',
        fullName: () => 'biography/fullName',
        homeCountry: () => 'biography/homeCountry',
        homeMunicipality: () => 'biography/homeMunicipality',
        profession: () => 'biography/profession',
        qualifications: Object.assign(() => 'biography/qualifications', {
            driversLicense: () => 'biography/qualifications/driversLicense',
            journalSystems: () => 'biography/qualifications/journalSystems',
            licenseStatuses: () => 'biography/qualifications/licenseStatuses',
            specializations: () => 'biography/qualifications/specializations',
        }),
    }),
    candidate: () => 'candidate',
    contactRequests: () => 'contactRequests',
    fileUploads: () => 'fileUploads',
    information: Object.assign(() => 'information', {
        email: () => 'information/email',
        personalIdentityNumber: () => 'information/personalIdentityNumber',
        phoneNumber: () => 'information/phoneNumber',
        hpr: () => 'information/hpr',
        graduatedAt: () => 'information/graduatedAt',
    }),
    interests: Object.assign(() => 'interests', {
        contactPreferences: Object.assign(() => 'interests/contactPreferences', {
            isInterestedInContact: () => 'interests/contactPreferences/isInterestedInContact',
            isInterestedInMatchingVacancies: () => 'interests/contactPreferences/isInterestedInMatchingVacancies',
        }),
        countries: () => 'interests/countries',
        departments: () => 'interests/departments',
        extent: () => 'interests/extent',
        jobTypes: () => 'interests/jobTypes',
        municipalities: () => 'interests/municipalities',
        regions: () => 'interests/regions',
        startDate: () => 'interests/startDate',
        workForms: () => 'interests/workForms',
    }),
    profileProgress: () => 'profileProgress',
    references: () => 'references',
    vacancies: () => 'vacancies',
    contract: () => 'contract', // TODO: split this up into subscopes?
};
