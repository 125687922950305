"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateEmploymentTypeFactory = void 0;
const locales_1 = require("../locales");
const translateEmploymentTypeFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('employmentType', locale);
    const tOther = (0, locales_1.getTranslation)('employmentTypePlural', locale);
    return (employmentType, count = 1) => {
        return count === 1 ? t[employmentType] : tOther[employmentType];
    };
};
exports.translateEmploymentTypeFactory = translateEmploymentTypeFactory;
